import {React, useState, useEffect} from "react";
import Footer from "./Footer";
import TopAppBar from "./TopAppBar";
import Main from "./Main";







function Home() {
  const domain = "qthtc.com";
  //update partners and footer
  return (
  <>
    <TopAppBar name={domain} />
    <Main domain={domain}/>
    <Footer />
  </>
  );

}


export default Home;