import {React, useState} from "react";
import validator from "email-validator";
import Partners from "./Partners";

export default function Main({domain}){
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [offer, setOffer] = useState('');
  const [message, setMessage] = useState('');
  const [outputText, setOutputText] = useState('')


  const [error, setError] = useState(null);
  const [customError, setCustomError] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isButtonClicked, setButtonClicked] = useState(false);


  function validEmail(emailInput) {
    return validator.validate(emailInput);
  }


  const postOffer =() => { 
    setButtonClicked(true);
    setIsLoaded(false);
    setOutputText('');    
    setError('');
    setCustomError('');


    const data = {name: name, email:email, phone:phone, amount:offer, domain:domain, message:message};
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data )
    };
    const url = `https://marketing-bmphr.ondigitalocean.app/domains/offers/`;

    if(name === ''){
      setIsLoaded(true);
      setCustomError("Please enter your name!");
    }
    else if( (email === '') || (validEmail(email) === false) ){
      setIsLoaded(true);
      setCustomError("Please enter a valid email!");
    }
    else if((offer === '') || (parseFloat(offer) === 0 )){
      setIsLoaded(true);
      setCustomError("Please enter an offer in USD!");
    }
    else
    {
        fetch(url, requestOptions)
        .then(res => res.text())
        .then(
        (result) => {
            setIsLoaded(true);
            setOutputText(result);

            setName('');
            setEmail('');
            setPhone('');
            setOffer('');
            setMessage('');
        },
        (error) => {
            setIsLoaded(true);
            setError(error);   
        });

    }      
          
}

    return (
        <>
          <section className="body-font text-base text-black-500 dark:text-white flex justify-center items-center">
            <div className="container px-5 py-20 mx-auto">  
              <div className="py-6">
                <div className="ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg">
                  <div className="mx-auto max-w-md px-4">
                    <h2 className="flex justify-center items-center mt-1"> Are you interested?</h2>
                    <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
                      
    

                      <div className="form-control relative w-full py-1">
                        <input type="text" className="input input-lg max-w-full pl-10" placeholder="Your name"  value={name} onChange={event => setName(event.target.value)} />
    
                        <span className="absolute inset-y-0 left-3 inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                        <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z"/></svg>
                        </span>
                      </div>
    
                      <div className="form-control relative w-full">
                        <input type="email" className="input input-lg max-w-full pl-10" placeholder="Your email"  value={email} onChange={event => setEmail(event.target.value)} />
    
                        <span className="absolute inset-y-0 left-3 inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/></svg>
                        </span>
                      </div>
    
                      <div className="form-control relative w-full py-1 mb-2">
                        <input type="tel" className="input input-lg max-w-full pl-10" placeholder="Your phone (optional)"  value={phone} onChange={event => setPhone(event.target.value)} />
    
                        <span className="absolute inset-y-0 left-3 inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z"/></svg>
                        </span>
                      </div>
    
                        <h2 className="flex justify-center items-center font-semibold"> My best offer for this domain is: </h2>
    
                        <div className="form-control relative w-full py-1">
                        <input type="number" className="input input-lg max-w-full pl-10" placeholder="Enter value in USD"  value={offer} onChange={event => setOffer(event.target.value)} />
    
                        <span className="absolute inset-y-0 left-3 inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                        </span>
                      </div>
                      <textarea className="textarea-block textarea" placeholder="Your message to us (optional)" value={message} onChange={event => setMessage(event.target.value)} />
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary btn-rounded btn-block mb-1"
                            onClick={postOffer}
                          >
                            Submit your best offer
                          </button>
                        </div>
                        <div className="flex justify-center items-center">
                            { customError && <p className="py-1 text-sm text-red-400">{customError}</p> }
                        </div>
                        <div className="flex justify-center items-center  text-red-400">
                            { error && <p className="py-1 text-sm text-red-400">{error.message}</p> }
                        </div>
                        <div className="flex justify-center items-center">
                            { isButtonClicked && !isLoaded && <button className="btn btn-square btn-sm"><span className="loading loading-spinner"></span></button>}
                        </div>
                        <div className="flex justify-center items-center">
                             <p className="py-1 text-sm text-purple-400 font-semibold">{outputText}</p>
                        </div>
                    </div>
                </div>
              </div>
            </div>
    
    
    
    
            <div className="px-2 py-2">
            <Partners />
            </div><br /> 
            </div>
    
    
    
          </section> 
    
      </>
      );
}