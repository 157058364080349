import {React} from "react";
import ionos from "../assets/images/ionos.png";
import hostinger from "../assets/images/hostingergradiant.jpg";
import namesilo from "../assets/images/namesilo.png";

function PartnerCard({_url, _image, _name,_description}){
    return (
        <>
            <div className="xl:w-1/3 md:w-1/3 p-2">
                <div className="card p-1 rounded-lg">
                <a href={_url} target="_blank"><img className="h-40 rounded w-full mb-6" src={_image} alt={_name}/></a>  
                <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font"></h3>
                <a href={_url} target="_blank" className="text-lg text-orange-600 font-medium title-font  px-2">{_name}</a>
                <a href={_url} target="_blank"  className="leading-relaxed text-gray-500 px-2 mb-4">{_description}</a>
                </div>
            </div>
        </>
    )
}


export default function Partners() {
    let more_domain_url = "https://www.namesilo.com/domain/search-domains?rid=3216c97gk";
    let hosting_provider_url = "https://hostinger.com?REFERRALCODE=1NARCISSE06";

    let partners = [
        {id:1, image:namesilo, url : "https://www.namesilo.com/domain/search-domains?rid=3216c97gk", name:"Namesilo", description:"Domains. Cheap, easy, and secure."},
        {id:2, image:hostinger, url : "https://hostinger.com?REFERRALCODE=1NARCISSE06", name:"Hostinger", description:"Everything you need to create a website."},
        {id:3, image:ionos, url : "https://aklam.io/yDqqtQ", name:"Ionos", description:"Buy cheap domain names."},
    ];

    return (
        <>
        <div className="w-full my-1 flex items-center sm:mx-auto sm:w-full before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
            <p className="mx-1 mb-0 text-center  dark:text-white">Want 
                <a href={more_domain_url} target="_blank"  className="text-sky-500 font-bold"> more domains</a>  or a 
                <a href={hosting_provider_url} target="_blank"   className="text-purple-500 font-bold"> hosting provider</a>?
            </p>
        </div>        
        <section className="text-gray-600 body-font">
        <div className="container px-5 py-4 mx-auto">
            <div className="flex flex-wrap w-full mb-20">
            <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-500">Our trusted partners</h1>
                <div className="h-1 w-20 bg-indigo-500 rounded"></div>
            </div>
            <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">
            If you want another domain or more domains or a website hosting provider, consider our trusted partners. 
            They have <span className="font-semibold text-gray-900 dark:text-gray-300">excellent customer support</span> and, more importantly, very 
            <span className="font-semibold text-gray-900 dark:text-gray-300"> competitive prices</span>.</p>
            </div>
            <div className="flex flex-wrap -m-4">

                {partners.map(p => 
                    <PartnerCard 
                    id={p.id}
                    key={p.name}
                    _name={p.name}
                    _image={p.image}
                    _url={p.url}
                    _description={p.description}
                    />
                )

                }
            </div>
        </div>
        </section>        
        </>
    )
}